<template>
    <div class="outerBox">
		<div class="videoMask" v-if="!videoSrc"></div>
		<video-player v-if="videoSrc" class="video-player vjs-custom-skin" :playsinline="true" :options="videoOption" style="object-fit: fill" @error="handleError"></video-player>
	</div>
</template>

<style lang="less">
.outerBox {
	height: 100%;
	width: 100%;
	position: relative;
	overflow: hidden;
	
	/* 当组件刚初始化未传入视频链接时，用遮罩层将其遮起来，避免其显示加载失败 */
	.videoMask {
		position: absolute;
		width: 100%;
		height: 100%;
		background: #000;
		z-index: 999;
	}
	
	::-webkit-scrollbar {
		display: none
	}
	
	.video-player {
		width: 100%;
		height: 100%;
	}
	
	.vjs-custom-skin >.video-js {
		padding: 0;
		width: 100%;
		height: 100%;
	}
	
	.vjs-custom-skin >.video-js >.vjs-tech {
		object-fit: fill
	}
}
</style>

<script>
export default {
	props:['videoSrc', 'vKey'],
	data(){
		return {
			videoOption: {}
		}
	},
	mounted(){
		this.loadVideo();
		return false;
	},
	watch: {
		videoSrc(){
			this.loadVideo();
		}
	},
	methods: {
		loadVideo(){
			let src = this.videoSrc;
			this.videoOption = {
				muted: true,
				preload: 'auto',
				autoplay: true,
				loop: false,
				language: 'zh-CN',
				fluid: true,
				playbackRates: [1.0],
				notSupportedMessage: '无法播放，请检查网络',
				controls: false,
				sources: [{
					withCredentials: false,
					type: 'application/x-mpegURL',
					src
				}],
				hls: true
			}
		},
		handleError(){
			console.log('视频加载错误'+this.vKey);
			
		}
	}
}
</script>

